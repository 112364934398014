import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { Result } from "antd";

export default function NotFoundPage() {
  const [countDown, setCountdown] = useState(3);

  useEffect(() => {
    let timer = setInterval(() => {
      setCountdown(prev => {
        let next = prev - 1;

        if (next <= 0) {
          clearInterval(timer);
          navigate("/");
        }

        return next;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div style={{ backgroundColor: "#f5f5f5", padding: 20 }}>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<div>{countDown} 秒後將回到首頁...</div>}
      />
    </div>
  );
}
